import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from './api.base.service';

@Injectable({ providedIn: 'root' })
export class NotificationService {
  private notificationHistoryURL = "notificationHistory";
  private notificationTemplatesURL = 'notificationTemplates'
  private notificationTemplateURL = 'notificationTemplate';
  private deleteNotificationTemplateURL = 'deleteNotification/'

  constructor(private api: ApiService) { }

  getNotificationHistory(): Observable<any[]> {
    return this.api.httpGET<any[]>(this.notificationHistoryURL, 'notificationHistory');
  }

  getNotificationTemplates(): Observable<any[]> {
    return this.api.httpGET<any[]>(this.notificationTemplatesURL, 'notificationTemplates');
  }
  getNotificationTemplates_(clientId: number): Observable<any[]> {
    const parameterValue = 'clientId=' + clientId;
    return this.api.httpGET<any[]>(`${this.notificationTemplatesURL}?${parameterValue}`, 'notificationTemplates');

  }
  saveNotificationTemplate(body): Observable<any> {
    return this.api.httpPOST(this.notificationTemplateURL, body, 'notificationTemplate');
  }

  getNotificationTemplate(templateId): Observable<any> {
    return this.api.httpGET<any>(this.notificationTemplateURL + `/${templateId}`, 'notificationTemplate');
  }

  deleteNotificationTemplate(nTmpId) {
    return this.api.httpDELETE(`${this.deleteNotificationTemplateURL}${nTmpId}`, 'project');
  }
}