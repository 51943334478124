export interface MARKER_LIST_MODEL {
    latitude: number;
    longitude: number;
    isMarker: boolean;
    callbackMarkerClick?: Function,
    markerRadius?: number;
    markerIconURL?: any | string,
    data?:any
}

export const createMARKER_LIST_MODELInstanceFNC = (latitude: number, longitude: number, isMarker: boolean, callbackMarkerClick: Function, data: any, markerRadius: number, markerIconURL?: any | string): MARKER_LIST_MODEL => {
    return {
        latitude,
        longitude,
        isMarker,
        callbackMarkerClick,
        markerRadius,
        data,
        markerIconURL,
    };
}
